// HOMEPAGE THREE CSS
.template-three {
    background: #F6F6F6;
}

// DOMAIN CHECKER CSS
.rts-domain-checker-two {
    .rts-hero__content {
        background: #0755F0;
        border-radius: 20px;
        text-align: center;
        position: relative;
        padding: 100px;
        z-index: 1;
        @include mq($sm) {
            padding: 30px;
        }

        &::before {
            @include ph;
            background-image: url(../images/domain/domain__finder__bg__four.svg);
            @include background;
        }

        &::after {
            @include ph;
            background: linear-gradient(93deg, #056d94 0.49%, #2181FC 0.5%, #2770FF 98.97%);
            z-index: -1;
            border-radius: inherit;
        }

        h1 {
            font-size: 55px;
            line-height: 1.15;
            margin-bottom: 25px;

            @include mq($xs) {
                font-size: 30px;
            }

            &::before {
                display: none;
            }
        }
        &.home-five{
            .description {
                display: block;
                max-width: 100%;
                font-size: 16px;
                font-weight: 400;
                margin-bottom: 0;
                max-width: 420px;
                @include mq($md){
                    margin-bottom: 50px;
                    
                }
            }
        }

        form {
            width: 830px;
            margin: auto;
            position: relative;

            @include mq($md) {
                width: 100%;
            }

            input {
                background: var(--color-white);
                height: 56px;
                border-radius: 6px;

                &::placeholder {
                    color: #787878;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 46px;
                }
            }

            .select-button-area {
                display: flex;
                align-items: center;
                width: max-content;
                position: absolute;
                top: 0;
                right: 0;

                .nice-select {
                    background: #F4F4F4;
                    height: 56px;
                    line-height: 53px;
                    border-radius: 0;
                    border: none;

                    &:focus {
                        border: none;
                    }
                }

                button {
                    background: #31b7e9;
                    height: 56px;
                    line-height: 56px;
                    padding: 0 40px;

                    @include mq($xs) {
                        padding: 0 10px;
                    }

                    border-radius: 0 6px 6px 0;
                }
            }
        }

        .banner-content-tag {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
            margin-top: 30px;
            flex-wrap: wrap;

            .tag-list {
                display: flex;
                padding: 0;
                margin: 0;
                gap: 10px;
                flex-wrap: wrap;
                @include mq($sm){
                    justify-content: center;
                }
                li {
                    list-style: none;
                    border-radius: 3px;
                    background: rgba(255, 255, 255, 0.10);
                    padding: 8px 9px;
                    display: flex;
                    align-items: center;
                    gap: 7px;

                    span {
                        color: var(--color-white);
                    }
                }
            }
        }
    }
    .abosolute-shape {
        position: absolute;
        left: -2%;
        bottom: 0;
        @include mq($md){
            display: none;
        }
    }
}

// FEATURE FOUR CSS
.rts-hosting-feature-four {
    .rts-section-description {
        display: flex;
        flex-direction: column;
        gap: 20px;

        a {
            color: var(--color-primary);
            font-weight: var(--semibold);
        }
    }

    .feature__single {
        background: #F4F9FF;
        padding: 35px 25px 35px 35px;
        border-radius: 10px;
        transition: var(--transition);

        &--icon {
            height: 80px;
            width: 80px;
            border-radius: 50%;
            background: #EAF1FF;
            display: grid;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;
            transition: var(--transition);
        }

        &--title {
            display: inline-block;
            font-size: 20px;
            font-weight: var(--bold);
            color: var(--color-secondary);
            margin-bottom: 15px;
            text-transform: capitalize;
        }

        &--description {
            font-weight: var(--regular);
            font-family: var(--font-secondary);
            margin-bottom: 30px;
        }

        &--btn {
            & .round__btn {
                background: #EAF1FF;
            }
        }

        &:hover {
            .feature__single {
                &--icon {
                    background: var(--color-primary);

                    svg path {
                        fill: var(--color-white);
                    }
                }

                &--btn {
                    & .round__btn {
                        background: var(--color-primary);
                        color: var(--color-white);
                    }
                }

            }
        }
    }
}

// SUPPORT AREA
.rts-support {
    &.black__friday--support{
        background: #F4F9FF;
        padding: 90px 0;
        & .rts-support__wrapper{
            padding: 0 80px;
            @include mq($md){
                padding: 0 40px;
            }
        }
    }
    &__wrapper {
        background: #F4F9FF;
        border-radius: 10px;
        padding: 60px 80px;
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 50px;
        
        @include mq($sm) {
            padding: 60px;
            display: flex;
            flex-direction: column-reverse;
            gap: 40px;
        }
        @include mq($xs) {
            padding: 20px;
        }


        &:before {
            @include ph;
            background-image: url(../images/support/support__bg.svg);
            background-repeat: no-repeat;
            background-position: 54% 60%;
            z-index: -1;
            @include mq($xs){
                display: none;
            }
        }
        &.bg-white{
            &:before {
                @include ph;
                background-image: url(../images/support/support__bg.svg);
                background-repeat: no-repeat;
                background-position: 54% 60%;
                z-index: -1;
                opacity: .5;
                @include mq($xs){
                    display: none;
                }
            }
        }

        &--content {
            h3 {
                font-weight: var(--bold);
                color: var(--color-secondary);
                margin-bottom: 20px;

                @include mq($xs) {
                    font-size: 30px;
                    line-height: 45px;
                }
            }

            p {
                max-width: 440px;
            }

            .feature {
                margin-top: 25px;
                margin-bottom: 30px;

                &__list {
                    @include ul;
                    column-count: 2;
                    width: 80%;
                    @include mq($md){
                        width: 90%;
                    }
                    @include mq($xs) {
                        width: 100%;
                    }

                    .feature__item {
                        position: relative;
                        margin-left: 25px;
                        color: var(--btc);
                        font-family: var(--font-secondary);
                        font-weight: var(--medium);
                        display: inline-block;
                        margin-bottom: 0;
                        text-transform: capitalize;

                        &::before {
                            position: absolute;
                            left: -25px;
                            top: 50%;
                            transform: translateY(-50%);
                            content: '\f00c';
                            font-family: var(--fontawesome);
                            height: 16px;
                            width: 16px;
                            font-size: 10px;
                            font-weight: 800;
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;
                            background: var(--color-primary);
                            color: var(--color-white);
                        }
                    }
                }
            }

            .support-btn {
                padding: 14px 24px;
            }

            .btn__two {
                color: var(--color-white);
                font-weight: var(--semibold);
            }
        }

        &--image {
            @include mq($sm) {
                position: unset;
                transform: none;
            }
        }
    }
}

// NEWSLETTER
.rts-newsletter {
    position: relative;

    &::before {
        position: absolute;
        height: 30%;
        content: '';
        background: #121833;
        width: 100%;
        bottom: 0;
    }

    &__box {
        padding: 70px;
        position: relative;
        text-align: center;
        color: var(--color-white);
        border-radius: 10px;

        @include mq($xs) {
            padding: 35px;
        }

        &::before {
            @include ph;
            background-image: url(../images/newsletter__bg.svg);
            @include background;
            z-index: 1;
            border-radius: inherit;
        }

        &::after {
            @include ph;
            background: var(--color-primary);
            border-radius: inherit;
            z-index: -1;
        }

        &--content {
            position: relative;
            z-index: 2;

            .title {
                margin-bottom: 40px;
                font-weight: var(--bold);

                @include mq($xs) {
                    font-size: 25px;
                    line-height: 35px;
                }
            }

            .newsletter__form {
                display: flex;
                gap: 10px;
                max-width: 610px;
                margin: 0 auto;

                @include mq($xs) {
                    flex-wrap: wrap;
                    gap: 20px;
                    justify-content: center;
                }

                input {
                    padding: 18px;
                    border-radius: 4px;
                    border: 0;
                    background: var(--color-white);
                    color: var(--btc);
                    max-width: 460px;
                    height: 45px;
                }

                button {
                    display: inline-block;
                    max-width: 140px;
                    padding: 13px;
                    font-weight: var(--semibold);
                    font-family: var(--font-secondary);
                    border-radius: 4px;
                }
            }

        }
    }
}