
// SITE ALL BUTTON STYLE HERE
.primary__bg {
    background: var(--color-primary);
    color: var(--btc);
}

.secondary__bg {
    background: var(--color-third);
    color: var(--body-bg);
    &:hover{
        background: #056d94 !important;
        color: var(--color-white) !important;
    }
}

.white__bg {
    background: var(--body-bg);
}

.border__btn {
    border-color: var(--border-color) !important;
}

.border__white {
    border-color: var(--body-bg) !important;
}

.primary__color {
    color: var(--color-primary);
}

.secondary__color {
    color: var(--color-secondary);
}

.white__color {
    color: var(--body-bg);
}

.btn__zero {
    padding: 0;

    i {
        margin-left: 10px;
    }
}

.primary__btn {
    padding: 15px 35px;
    border-radius: 30px;
    display: block;
    line-height: 100%;
    font-size: var(--size-default);
    font-weight: 600;
    font-family: var(--font-secondary);
    cursor: pointer;
    border: 1px solid transparent;
    transition: var(--transition);
    text-transform: capitalize;
    max-width: max-content;

    & i {
        margin-left: 5px;
    }
    &:hover{
        background: var(--color-third);
        color: var(--color-secondary);
    }
}

.secondary__btn {
    padding: 15px 35px;
    border-radius: 6px;
    display: inline-block;
    line-height: 100%;
    font-size: var(--size-default);
    font-weight: var(--medium);
    font-family: var(--font-secondary);
    cursor: pointer;
    border: 1px solid transparent;
    transition: var(--transition);
    text-transform: capitalize;

    & i {
        margin-left: 5px;
    }
}

.rts-btn {
    border-radius: 4px;
    display: inline-block;
    line-height: 100%;
    font-size: var(--size-default);
    font-weight: 600;
    font-family: var(--font-secondary);
    cursor: pointer;
    border: 1px solid transparent;
    transition: var(--transition);
    text-transform: capitalize;
    & i {
        margin-left: 5px;
    }

    &:hover {
        background: var(--color-third) !important;
        color: var(--color-secondary) !important;
        border-color: transparent !important;
    }
}

// BTN STYLE TWO
.btn__two {
    padding: 12px 18px;
    border-radius: 6px;
    text-transform: capitalize;
    display: inline-block;
    transition: var(--transition);
    i {
        margin-left: 5px;
    }
}

.btn__long {
    padding: 18px 33px;
    border-radius: 6px;
    @media(max-width:576px){
        padding: 18px 24px;
    }
}
.rts-btn-secondary{
    background: var(--color-third);
    padding: 16px 35px;
    color: var(--color-secondary);
    font-family: var(--font-primary);
    transition: var(--transition);
    &:hover{
        background: #056d94 !important;
        color: var(--color-white) !important;
    }
}
.rts-btn-primary{
    background: var(--color-primary);
    padding: 16px 35px;
    color: var(--color-white);
    font-family: var(--font-primary);
}
// ROUND BTN
.round__btn {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: #EAF1FF;
    color: var(--color-secondary);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: var(--transition);

}
.hover-white{
    background: var(--color-third);
}
.hover-white:hover{
    background: var(--color-white) !important;
    color: var(--color-secondary) !important;
}